import React,{useEffect} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "./components/Grid/GridContainer";
import GridItem from "./components/Grid/GridItem";
import { Button, Typography } from "@material-ui/core";
import CardMedia from '@material-ui/core/CardMedia';
import { Link } from "react-router-dom";
import { connect, useSelector, useDispatch } from 'react-redux';
import ActivityTable from './ActivityTable'
import { fetchActivities } from "../actions/activityActions"
import NewActivityForm from "./NewActivityForm";



export default function AdminActivities(){

    const dispatch = useDispatch()
    
    useEffect(() => {
    
        dispatch(fetchActivities())
  
    }, [])
    
    const activities=useSelector(state=>state.activity.items)

    return (

        
        <React.Fragment>
       
            <GridContainer >
          
                <GridItem md={12}>
              
      <NewActivityForm/>
                   
                    <ActivityTable activities={activities}/>

                </GridItem>
                


             </GridContainer>
           
        </React.Fragment>
    )
}
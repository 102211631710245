import { FETCH_ACTIVITIES, START_FETCH_ACTIVITIES, FETCH_ACTIVITY, NEW_ACTIVITY, DELETE_ACTIVITY ,UPDATE_ACTIVITY} from '../actions/types'

const initialState = {
    items: [],
    item: {},
    loading:true
}


export default function (state=initialState, action) {

    switch (action.type) {
        case START_FETCH_ACTIVITIES:
            if (state.loading)
                return {
                    ...state,
                    loading: false
                }
            else {
                return {
                    ...state,
                    loading: true
                }
            }
     
        case FETCH_ACTIVITIES:
            return {
                ...state,
                items:action.payload,
                loading: false
            };
            case FETCH_ACTIVITY:
                return {
                    ...state, 
                    item: action.payload
            } ;
        case NEW_ACTIVITY:
            return {
                ...state,
                item: action.payload,
            }
            case DELETE_ACTIVITY:
                return {
                    ...state,
                    item:action.payload
            }
            case UPDATE_ACTIVITY:
                return {
                    ...state,
                    item:action.payload
            }
        default:
            return state;
    }
}
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Schedule from "@material-ui/icons/Schedule";
import List from "@material-ui/icons/List";

// core components
import GridContainer from "./components/Grid/GridContainer.js";
import GridItem from "./components/Grid/GridItem.js";
import NavPills from "./components/NavPills/NavPills.js";

import styles from "../assets/jss/material-kit-react/views/componentsSections/pillsStyle.js";
import CommuteIcon from '@material-ui/icons/Commute';
import HomeIcon from '@material-ui/icons/Home';
import EventIcon from '@material-ui/icons/Event';
import ActivityForm from "./ActivityForm.js";
import AccomodationForm from "./AccomodationForm.js";
import TransferForm from "./TransferForm.js";
import { Grid, Typography } from "@material-ui/core";
import Example1 from "./components/Example1.js";
import ActivitiesHome from "./components/ActivitiesHome.js";
import  ActivitiesHome1 from "./components/ActivitiesHome1.js";



const useStyles = makeStyles(styles);

export default function SectionPills() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div id="navigation-pills">
        
          
            <Grid
            container
            style={{ width: '100%',paddingLeft:'5px',paddingRight:'5px' }}
            
            >

           
            <Grid
            item
              xs={12} sm={12} md={8} lg={8}>
              <NavPills
                color="primary"
                tabs={[
                  {
                    tabButton: "Activities",
                    tabIcon: EventIcon,
                    tabContent: (
                      <span>
                        <p>
                          Find Activities
                              <ActivityForm/>
                        </p>
                        <br />
                   
                      </span>
                   
                    )
                  },
                  {
                    tabButton: "Accomodation",
                    tabIcon: HomeIcon,
                    tabContent: (
                      <span>
                        <p>
                          Find Accomodation
                           <AccomodationForm/>
                        </p>
                      
                      </span>
                    )
                  },
                  {
                    tabButton: "Tranfers",
                    tabIcon: CommuteIcon,
                    tabContent: (
                      <span>
                        <p>
                          Form to book transfers
                            <TransferForm/>
                        </p>
                     
                      </span>
                    )
                  }
                ]}
              />
            </Grid>
          
            </Grid>
          
        </div>
      </div>
    </div>
  );
}

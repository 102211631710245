import React from 'react'
import LoginForm from './LoginForm'
import { Grid } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

function LoginPage() {
    const useStyles = makeStyles((theme) => ({
         root: {
          
             flex:1,
            height: '100%',
            
        },
    }))
        const classes=useStyles()
    return (
       
        <React.Fragment>
        
      <Container className={classes.root} component='div'>
           <br></br>
                <Typography>Welcome to Kalundu Sands Victoria Falls</Typography> 
                <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                style={{marginTop:'10%', height:'100%' }}>

                    
             <Grid
        
                item
                md={4}
                >
                  <LoginForm />   

            </Grid>
                </Grid>
                <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                    style={{ marginBottom: '0%', }}>
                    <Grid item>
                  
                    </Grid>
                    </Grid>
               
           
            </Container>  
            </React.Fragment>
    )
}

export default LoginPage

import React, { useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
    DateTimePicker,
} from '@material-ui/pickers';
import { Button, Link } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { fetchActivityTypes } from "../actions/activityTypeActions"
import { fetchActivities } from "../actions/activityActions"
import { addToCart } from "../actions/cartActions"
import { withRouter } from 'react-router';
import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/brown'

const theme = createMuiTheme({
  palette: {
    primary:red,
  },
});
  


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));



function ActivityForm(props) {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchActivities())
  }, [])

  const activities = useSelector(state => state.activity.items)
  const classes = useStyles();

  const [pax, setPax]=useState()
  const[myError,setMyError]=useState(false)

  const times = [
    { time: '9:00', when: 'AM' },
    { time: '10:00', when: 'AM' },
    { time: '11:00', when: 'AM'},
    { time: '12:00', when: 'AM' },
    { time: '13:00', when: 'PM' },
    { time: '14:00', when: 'PM' },
    { time: '15:00', when: 'PM' },
    { time: '16:00', when: 'PM' },
    { time: '17:00', when: 'PM' },
  
  ]
  
  const defaultProps = {
    options: activities,
    getOptionLabel: (activity) => activity.name+ ' $'+activity.price,
  };

  const flatProps = {
    options:activities.map((activity) => activity.name),
  };


  const defaultProps2 = {
    options: times,
    getOptionLabel: (t) => t.time,
  };

  const flatProps2 = {
    options:times.map((t) => t.time),
  };


  const [value, setValue] = React.useState(null);
  const [time, setTime] = React.useState(null);

  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [selectedTime, setSelectedTime] = React.useState(new Date());
  const [submited, setSubmited] = useState(false)
  const cart = useSelector(state => state.cart.items)
    
  useEffect(() => {
  console.log(submited)
 if(submited) props.history.push('/book')
}, [cart])

    const handleDateChange = date => {
        setSelectedDate(date);
  };
  
  const handleTimeChange = time => {
    setSelectedDate(time);
  };
        

  const [state, setState] = React.useState({
    age: '',
    name: 'hai',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handlePax = (e) => {
    setPax(e.target.value)
  }

  const handleSubmit = () => {
    console.log(value)
   // const myactivity = activities.find(a => a.name == value)
   // console.log(myactivity)
    if (value && pax && time) {
      const activity = {
        id: value.id,
        name: value.name,
        pax: pax,
        date: selectedDate,
        time: time.time,
        when: time.when,
        total_cost: value.price * pax,
        details: ''
      
      
      }
      
      dispatch(addToCart(activity))
      //  console.log(activity)
      setSubmited(true)
      //props.history.push('/book')
    }

    else
      setMyError(true)
    
  }
    
  return (
    
      <form className={classes.root} noValidate autoComplete="off">
          <MuiThemeProvider theme={theme}>
          <FormControl className={classes.formControl}>

          <Autocomplete
        {...defaultProps}
        id="controlled-demo"
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => <TextField {...params} label="Find Activity" margin="normal" />}
      />       

      </FormControl> 
      <FormControl className={classes.formControl}>
      <TextField id="filled-basic" type="number" inputProps={{ min: 0, max: 100 }} value={pax} margin="normal" onChange={handlePax} label="Number of People"  />
      </FormControl>
   
        {/* <TextField id="filled-basic" type="number" value={pax} onChange={handlePax} label="Number of People" variant="filled" /> */}
         
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
             
        <FormControl className={classes.formControl}>
          
        <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label="Date "
          format="MM/dd/yyyy"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          />
        </FormControl> 
        </MuiPickersUtilsProvider>
        
        <FormControl className={classes.formControl}>
           <Autocomplete
        {...defaultProps2}
        id="controlled-demo"
        value={time}
        onChange={(event, newValue) => {
          setTime(newValue);
        }}
        renderInput={(params) => <TextField {...params} label="Time" margin="normal" />}
      />  
</FormControl>

</MuiThemeProvider>    
          
      
     
          
          <br></br>
          <Button
          
          variant="contained"
        
        style={{ width: "100px", textTransform: "none", color: "white", backgroundColor: "#ac7339" }}
      onClick={handleSubmit}
      >
        Book 
              </Button>
      
      {myError&&<p style={{color:'red'}}>*All fields are required</p>}

      </form>
    
  );
}

export default withRouter(ActivityForm)
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const POST_SUCCESS = 'POST_SUCCESS';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';


export const START_FETCH_ACTIVITY_TYPES = 'START_FETCH_ACTIVITY_TYPES';
export const FETCH_ACTIVITY_TYPES = 'FETCH_ACTIVITY_TYPES';
export const FETCH_ACTIVITY_TYPE = 'FETCH_ACTIVITY_TYPE';
export const NEW_ACTIVITY_TYPE = 'NEW_ACTIVITY_TYPE';
export const UPDATE_ACTIVITY_TYPE = 'UPDATE_ACTIVITY';
export const DELETE_ACTIVITY_TYPE = 'DELETE_ACTIVITY_TYPE';

export const START_FETCH_ACTIVITIES  = 'START_FETCH_ACTIVITIES';
export const FETCH_ACTIVITIES = 'FETCH_ACTIVITIES';
export const FETCH_ACTIVITY = 'FETCH_ACTIVITY';
export const NEW_ACTIVITY = 'NEW_ACTIVITY';
export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';
export const DELETE_ACTIVITY = 'DELETE_ACTIVITY';

export const START_FETCH_BOOKINGS = 'START_FETCH_BOOKINGS';
export const FETCH_BOOKINGS = 'FETCH_BOOKINGS';
export const FETCH_BOOKING = 'FETCH_BOOKING';
export const NEW_BOOKING = 'NEW_BOOKING';
export const DELETE_BOOKING = 'DELETE_BOOKING';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const SET_USER = 'SET_USER';
export const UPDATE_USER = 'UPDATE_USER';

export const ADD_CART = 'ADD_CART';
export const REMOVE_CART = 'REMOVE_CART';
export const CLEAR_CART = 'CLEAR_CART';



import React, {useState, useEffect} from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { removeFromCart } from '../actions/cartActions';
import { clearCart} from '../actions/cartActions';
import { Button, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Moment from 'react-moment';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
    paper: {
      width:"100%",
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function NestedGrid() {
    const classes = useStyles();
    
    const dispatch = useDispatch() 
    const cart = useSelector(state => state.cart.items)

    useEffect(() => {
       
    }, [cart])

  

    const handleDelete = (id,e) => {
        console.log(id)
        dispatch(removeFromCart(id))
    
}

const clearCart2 = (e) => {
  dispatch(clearCart())

}
    function FormRow({ activity, index }) {

    return (
      <React.Fragment>
    
            <Grid item xs={5}>
                
                <Typography>{activity.name}</Typography>
                <Typography> <Moment format="DD MMM Y">{activity.date}</Moment></Typography>
          <Typography>{activity.time}</Typography>
        </Grid>
        <Grid item xs={3}>
          {activity.pax} People
            </Grid>
        <Grid item xs={2}>
          ${activity.total_cost}
        </Grid>
            <Grid item xs={2}>
                
                <Button name="ghs" onClick={(e) => { handleDelete(index,e) }}><DeleteIcon/></Button>
                </Grid>
        
      </React.Fragment>
    );
  }

  return (
    <div className={classes.root} style={{marginTop:'10%', padding:'10px'}}>
    <Paper elevation={3}>
    <Grid container spacing={1}>
              {cart.map((c,index) =>
                  <Grid key={index} container item xs={12} spacing={3}style={{padding:'10px'}}>
                      <FormRow index={index} activity={c} />
                      
                  </Grid>
                  
                  
                  )}  

<Grid  container item xs={12} spacing={3} direction="row"
  >
                      <Grid item xs={6}><Typography></Typography></Grid>
                      <Grid item xs={6}><Typography>Total = {cart.reduce((a, b) => a + (b['total_cost'] || 0), 0)}</Typography> </Grid>
                      
                    
                      
                  </Grid>

                  <Grid  container item xs={12} spacing={3} direction="row"
  >
                      <Grid item xs={6}><Button onClick={clearCart2}>Clear Items</Button></Grid>
                      
                      
                    
                      
                  </Grid>
                  
              </Grid>
    </Paper>  
    </div>
  );
}

import { FETCH_BOOKINGS, START_FETCH_BOOKINGS, FETCH_BOOKING, NEW_BOOKING, DELETE_BOOKING,POST_SUCCESS,GET_ERRORS } from  '../actions/types'

const initialState = {
    items: [],
    item: {},
    loading:true
}


export default function (state=initialState, action) {

    switch (action.type) {
        case START_FETCH_BOOKINGS:
            if (state.loading)
                return {
                    ...state,
                    loading: false
                }
            else {
                return {
                    ...state,
                    loading: true
                }
            }
     
        case FETCH_BOOKINGS:
            return {
                ...state,
                items: action.payload,
                loading: false
            };
            case FETCH_BOOKING:
                return {
                    ...state, 
                    item: action.payload
            } ;
        case NEW_BOOKING:
            return {
                ...state,
                item: action.payload,
            }
            case DELETE_BOOKING:
                return {
                    ...state,
                    item:action.payload
            }
        default:
            return state;
    }
}
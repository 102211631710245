import { ADD_CART, REMOVE_CART, CLEAR_CART } from '../actions/types';

const initialState = {
    items: [],
    item: {},
    added:false
}


export default function (state=initialState, action) {

    switch (action.type) {
        case ADD_CART:
            
                return {
                    ...state,
                    items: [...state.items, action.payload]
                }
            
     
        case REMOVE_CART:
            return {
                ...state,
                items: state.items.filter((val, i) => i !== action.payload ),
                loading: false
            };
            case CLEAR_CART:
                return {
                    ...state,
                    items: [],
                    loading: false
                };
            
        default:
            return state;
    }
}
import React,{useState, useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import './components/NavBar'
import NavBar from './components/NavBar';
import HeaderCaurosel from './components/HeaderCaurosel';
import { makeStyles } from "@material-ui/core/styles";
import Example from './components/Example'
import Home from './components/Home'
import AboutUs from './components/AboutUs'
import Admin from './components/Admin'
import Login from './components/LoginPage'
import Register from './components/RegisterPage'
import { Route } from 'react-router-dom'
import { Provider, useSelector } from 'react-redux'
import store from './store'
import ViewCart from './components/ViewCart'
import BookingPage from './components/BookingPage'
import CouroselMobile from './components/CorouselMobile'
import Footer from './components/components/Footer/Footer';

function App() {

  const useStyles = makeStyles(theme => ({
    offset: theme.mixins.toolbar,
    content: {
      position: 'relative',
      minHeight: '100vh'
       
    },
    footer: {
      position: 'absolute',
  bottom: 0,
  width: '100%',
  height: '2.5rem'

    }
  }))

  const user = useSelector(state => state.user.loggedin)
  const myuser = useSelector(state => state.user.user)
  const [isMobile,setIsMobile]=useState(window.innerWidth < 480)

  useEffect(() => {
  
    const access = localStorage.getItem('access_token')
    if (access) {

      //store.dispatch(setuser(access))
    }

  }, []) 
  
  useEffect(() => {
    window.addEventListener('resize', ()=>{setIsMobile(window.innerWidth < 480)});
  }, [])

  const myhome = () => {
    //const isMobile = window.innerWidth < 480;

    return (
      <React.Fragment>

        {isMobile ? <CouroselMobile /> : <Example />}
      
       <Home/>
      </React.Fragment>
    )
  }
  
  const classes = useStyles();
  return (
    
    <div className="App" >
      <NavBar />
      <div className={classes.offset} />
      <Route path="/" exact component={myhome} />
      <Route path="/aboutus" exact component={AboutUs} />

      <Route path="/admin" exact  render={props => {
              return (
                ( 
                  <div>
                    {user ?
                      <Admin/>
                      :
                      <React.Fragment>
                      
                          <Login />
                     
                      </React.Fragment>
                  }
                  </div>
                )
              )
      }} />
     
        <Route path="/login" exact component={Login} />
      <Route path="/register" exact component={Register} />
      <Route path="/cart" exact component={ViewCart} />
      <Route path="/book" exact component={BookingPage} />

      <div >
        <Footer style={{ backgroundColor: 'black' }} />
      </div>

      </div>
    
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "./components/Grid/GridContainer";
import GridItem from "./components/Grid/GridItem";
import { Button, Typography } from "@material-ui/core";
import CardMedia from '@material-ui/core/CardMedia';
import { Link } from "react-router-dom";
import Bookings from "./AdminBookings";
import Activities from "./AdminActivities"
import ActivityTypes from "./AdminTypes"
import { fetchActivityTypes } from "../actions/activityTypeActions"
import { fetchActivities } from "../actions/activityActions"

const useStyles= makeStyles((theme)=>({
    btn: {
        textTransform: 'none',
        
    },
    links: {

        paddingLeft: '30px',
        paddingRight: '30px'
    }
}))


export default function Admin() {
    
    const [page, setPage] = useState('booking'); 

    const classes = useStyles();

    const dispatch = useDispatch();
useEffect(() => {
    
}, [])

  

    const handlePages=(e) => {
    console.log(e.currentTarget.name)
        if (e.currentTarget.name == 'booking')
           setPage('booking')
        else if (e.currentTarget.name == 'types')
         setPage('types')
        else if (e.currentTarget.name = 'activities')
        setPage('activities')
}

    return (

        <React.Fragment>
            <Typography variant="h5" component='h2'> Admin</Typography>  
            <hr></hr>
            <GridContainer >
                <GridItem md={2} className={classes.links}>
                    <GridContainer
                         direction="column"
                         justify="center"
                         alignItems="center"
                    style={{paddingLeft:'10px', paddingRight:'10px', paddingTop:"50px"}}
                    >
                    
                <Button name="booking" className={classes.btn} onClick={handlePages}>Manage Bookings</Button>
                <Button name="types" className={classes.btn} onClick={handlePages}> Manage Categories  </Button>
                <Button name="activities" className={classes.btn} onClick={handlePages}> Manage Activities</Button>

                 </GridContainer>
                </GridItem>
                

                <GridItem md={8}>
                    {page == 'booking' && <Bookings />}
                    {page == 'activities' && <Activities />}
                    {page=='types'&&<ActivityTypes />}
                    

                </GridItem>
                <GridItem md={2}>
                
                    

                </GridItem>


             </GridContainer>
            <p>
            

            </p>
        </React.Fragment>
    )
}
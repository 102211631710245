import { FETCH_ACTIVITIES, START_FETCH_ACTIVITIES, FETCH_ACTIVITY, NEW_ACTIVITY, UPDATE_ACTIVITY, DELETE_ACTIVITY,POST_SUCCESS,GET_ERRORS ,DELETE_SUCCESS} from '../actions/types'
import axios from 'axios'
import { APP } from '../constants'

export function fetchActivities() {
    
    return async function (dispatch) {
         
    dispatch({
        type: START_FETCH_ACTIVITIES,
      }) 
 
        await axios.get(APP.url+"/api/activities", {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    )  
        .then(activitytypes => dispatch({
              type: FETCH_ACTIVITIES,
              payload:activitytypes.data
        })).then(
            dispatch({
                type: START_FETCH_ACTIVITIES,  // toggle fetching
              }) 
          )  
          .catch(function (error) {
            console.log(error);
            // dispatch({
            //     type:GET_ERRORS,
            //     payload: error
            // })
          });

  }

}


export function fetchActivity(id) {
    
  return async function (dispatch) {
       
  dispatch({
      type: START_FETCH_ACTIVITIES,
    }) 

      await axios.get(APP.url+"/api/activity/"+id, {
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
          }
      }
  )  
      .then(activity => dispatch({
            type: FETCH_ACTIVITY,
            payload:activity.data
      })).then(
          dispatch({
              type: START_FETCH_ACTIVITIES,  // toggle fetching
            }) 
        )  
        .catch(function (error) {
          console.log(error);
          // dispatch({
          //     type:GET_ERRORS,
          //     payload: error
          // })
        });

}

}

/* 
export function fetchArticle(id) {
  
        
              
  return async function (dispatch) {
    
    await axios.get("http://portalapi.test/api/article/"+id)
      .then(article => {
        if (!article.data.error) {
          dispatch({
            type: FETCH_ARTICLE,
            payload: article.data
          })
      
        }
        else {
          
          dispatch({
            type: GET_ERRORS,
            payload:article.data.error

          })
        }
      })
      .catch(function (error) {
        console.log(error);
        dispatch({
            type:GET_ERRORS,
            payload: error
        })
      });
     

}
}

 */

export function newActivity(activity) {

  const token0 =localStorage.getItem('access_token')?localStorage.getItem('access_token'):'fail'//"Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjIwOTZiNTNmMWE3N2U4NjUzOWIyNzcxMTE0YmIxMjE2YjRiOTVkYWE5YmYxYWMxN2U1OWE0Yjk2ZDUyZjE1N2MxZGM2NDM1MGE1ZmY3ODgwIn0.eyJhdWQiOiIxIiwianRpIjoiMjA5NmI1M2YxYTc3ZTg2NTM5YjI3NzExMTRiYjEyMTZiNGI5NWRhYTliZjFhYzE3ZTU5YTRiOTZkNTJmMTU3YzFkYzY0MzUwYTVmZjc4ODAiLCJpYXQiOjE1ODE4MzE3NDQsIm5iZiI6MTU4MTgzMTc0NCwiZXhwIjoxNjEzNDU0MTQ0LCJzdWIiOiIyIiwic2NvcGVzIjpbXX0.BkzvcCcKAKJ72D3oQOOFpicZ1ek4Dl2aOTJJhnc4hn-t6h2IzYocKgkcaDV_Ux2LJS01iVa1FoxKdiLjNi0vjReI6Liw3oJMD9xOs0uThb-QdiRLzCUGFdQpjr7ifTrOwcmLrGwa6XwrAtZ_cP3pw2QpLYzIaphCY77LQMwzEmSQ85nq2vEMTL852WlbqyCB2rfXhXYIF-tJF0D4Y2zQMIYpS3gU3Wy7OuZi0Ui-yFHKmFpqTo9IkaRrI8tcG7xGcvHAnIQbE9HYYLF421fieNxVLBq51_QA12KLhitbnwWLd5p5dhK4xVGyHza7LWkyDcdEfveoTLPTIyekx3of1RDXIW2x1-1DK6orL2M5E5Q04sh95-oQruze4aa4NqXq_2khdX9wnr9Uo_xAYEkLDrj53YV_i6BT2KWfT8Z-DsyBJycAnMkxthdjfVpgwp0Po-sspUsDYMMDhIiiYFjc42oRD16-jQLchy1O3O8Hd-o0uyxR8HoaCpkxzT6V0yVFr1d0P9Skg-fC2pZGfxdkEjN0DaZwkfsBqO4amPWCSyHNH0coFlkHgs_hm3R6w4IcMwIqsYRCNI4aTZP5n2UspkXv3ODmAuueZ38VZgVtHNcQ8C6PvnzusBv_T9Kmsm-Ncb1RV7tzGjMe1O2fty6YQNHa3SHcv5ruW-y67uk-C7o"
    const token = 'Bearer ' + token0
    
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
  }
  
  
  
  return async function (dispatch) {
  
      
      await axios.post(APP.url+'/api/activity', activity, {
       headers:headers
      }).then(activity => {
  
        if (activity.data.error) {
          dispatch({
            type:GET_ERRORS,
            payload: activity.data.error
        })
        }
        else {
  
          dispatch({
            type: NEW_ACTIVITY,
            payload: activity.data
          })
  
          dispatch({
            type: POST_SUCCESS,
          })
          
        }
       
      }
      
      ) 
      .catch(function (error) {
        console.log(error);
        dispatch({
            type:GET_ERRORS,
            payload: error
        })
      });
  
    }
  }
  
  
export function deleteActivity(id) {
   

  const token0 =localStorage.getItem('access_token')?localStorage.getItem('access_token'):'fail'//"Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjIwOTZiNTNmMWE3N2U4NjUzOWIyNzcxMTE0YmIxMjE2YjRiOTVkYWE5YmYxYWMxN2U1OWE0Yjk2ZDUyZjE1N2MxZGM2NDM1MGE1ZmY3ODgwIn0.eyJhdWQiOiIxIiwianRpIjoiMjA5NmI1M2YxYTc3ZTg2NTM5YjI3NzExMTRiYjEyMTZiNGI5NWRhYTliZjFhYzE3ZTU5YTRiOTZkNTJmMTU3YzFkYzY0MzUwYTVmZjc4ODAiLCJpYXQiOjE1ODE4MzE3NDQsIm5iZiI6MTU4MTgzMTc0NCwiZXhwIjoxNjEzNDU0MTQ0LCJzdWIiOiIyIiwic2NvcGVzIjpbXX0.BkzvcCcKAKJ72D3oQOOFpicZ1ek4Dl2aOTJJhnc4hn-t6h2IzYocKgkcaDV_Ux2LJS01iVa1FoxKdiLjNi0vjReI6Liw3oJMD9xOs0uThb-QdiRLzCUGFdQpjr7ifTrOwcmLrGwa6XwrAtZ_cP3pw2QpLYzIaphCY77LQMwzEmSQ85nq2vEMTL852WlbqyCB2rfXhXYIF-tJF0D4Y2zQMIYpS3gU3Wy7OuZi0Ui-yFHKmFpqTo9IkaRrI8tcG7xGcvHAnIQbE9HYYLF421fieNxVLBq51_QA12KLhitbnwWLd5p5dhK4xVGyHza7LWkyDcdEfveoTLPTIyekx3of1RDXIW2x1-1DK6orL2M5E5Q04sh95-oQruze4aa4NqXq_2khdX9wnr9Uo_xAYEkLDrj53YV_i6BT2KWfT8Z-DsyBJycAnMkxthdjfVpgwp0Po-sspUsDYMMDhIiiYFjc42oRD16-jQLchy1O3O8Hd-o0uyxR8HoaCpkxzT6V0yVFr1d0P9Skg-fC2pZGfxdkEjN0DaZwkfsBqO4amPWCSyHNH0coFlkHgs_hm3R6w4IcMwIqsYRCNI4aTZP5n2UspkXv3ODmAuueZ38VZgVtHNcQ8C6PvnzusBv_T9Kmsm-Ncb1RV7tzGjMe1O2fty6YQNHa3SHcv5ruW-y67uk-C7o"
  const token = 'Bearer ' + token0
   
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token
}



return async function (dispatch) {

    
    await axios.delete(APP.url+"/api/activity/"+id, {
     headers:headers
    }).then(activity => { 
        if (activity.data.error) {
          dispatch({
            type: GET_ERRORS,
            payload: activity.data.error
          })

        }

        else {
          
          dispatch({
            type: DELETE_ACTIVITY,
            payload: activity.data
          })
          dispatch({
            type: DELETE_SUCCESS,
          })
          
        }
       
      })
      .catch(function (error) {
        console.log(error);
        dispatch({
            type:GET_ERRORS,
            payload: error
        })
      });

  }



}




export function updateActivity(activity,id) {

  const token0 =localStorage.getItem('access_token')?localStorage.getItem('access_token'):'fail'//"Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjIwOTZiNTNmMWE3N2U4NjUzOWIyNzcxMTE0YmIxMjE2YjRiOTVkYWE5YmYxYWMxN2U1OWE0Yjk2ZDUyZjE1N2MxZGM2NDM1MGE1ZmY3ODgwIn0.eyJhdWQiOiIxIiwianRpIjoiMjA5NmI1M2YxYTc3ZTg2NTM5YjI3NzExMTRiYjEyMTZiNGI5NWRhYTliZjFhYzE3ZTU5YTRiOTZkNTJmMTU3YzFkYzY0MzUwYTVmZjc4ODAiLCJpYXQiOjE1ODE4MzE3NDQsIm5iZiI6MTU4MTgzMTc0NCwiZXhwIjoxNjEzNDU0MTQ0LCJzdWIiOiIyIiwic2NvcGVzIjpbXX0.BkzvcCcKAKJ72D3oQOOFpicZ1ek4Dl2aOTJJhnc4hn-t6h2IzYocKgkcaDV_Ux2LJS01iVa1FoxKdiLjNi0vjReI6Liw3oJMD9xOs0uThb-QdiRLzCUGFdQpjr7ifTrOwcmLrGwa6XwrAtZ_cP3pw2QpLYzIaphCY77LQMwzEmSQ85nq2vEMTL852WlbqyCB2rfXhXYIF-tJF0D4Y2zQMIYpS3gU3Wy7OuZi0Ui-yFHKmFpqTo9IkaRrI8tcG7xGcvHAnIQbE9HYYLF421fieNxVLBq51_QA12KLhitbnwWLd5p5dhK4xVGyHza7LWkyDcdEfveoTLPTIyekx3of1RDXIW2x1-1DK6orL2M5E5Q04sh95-oQruze4aa4NqXq_2khdX9wnr9Uo_xAYEkLDrj53YV_i6BT2KWfT8Z-DsyBJycAnMkxthdjfVpgwp0Po-sspUsDYMMDhIiiYFjc42oRD16-jQLchy1O3O8Hd-o0uyxR8HoaCpkxzT6V0yVFr1d0P9Skg-fC2pZGfxdkEjN0DaZwkfsBqO4amPWCSyHNH0coFlkHgs_hm3R6w4IcMwIqsYRCNI4aTZP5n2UspkXv3ODmAuueZ38VZgVtHNcQ8C6PvnzusBv_T9Kmsm-Ncb1RV7tzGjMe1O2fty6YQNHa3SHcv5ruW-y67uk-C7o"
    const token = 'Bearer ' + token0
    
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
  }
  
  
  
  return async function (dispatch) {
  
      
      await axios.put(APP.url+"/api/activity/"+id, activity, {
       headers:headers
      })
        .then(  activity =>{
  
          if (activity.data.error) {
            dispatch({
              type: GET_ERRORS,
              payload:activity.data
            })
  
          
        
          }
  
          else {
            dispatch({
              type: UPDATE_ACTIVITY,
          payload:activity.data
            })
  
            dispatch({
              type: POST_SUCCESS,
            })
          }
        }
          ) 
          .catch(function (error) {
            console.log(error);
            dispatch({
                type:GET_ERRORS,
                payload: error
            })
          });
      
  
    }
  }
  
import React from 'react';
import Carousel from "react-material-ui-carousel"
import autoBind from "auto-bind"
import './CorouselMobile.scss';
import falls1 from '../assets/img/fall1.jpeg'
import falls2 from '../assets/img/falls2.jpeg'


import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    Grid,
    Button,
    Checkbox,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormLabel,
    Slider
} from '@material-ui/core';

function Banner(props) {
    if (props.newProp) console.log(props.newProp)
    const contentPosition = props.contentPosition ? props.contentPosition : "left"
    const totalItems = props.length ? props.length : 3;
    const mediaLength =  1;

    let items = [];
    const content = (
        <Grid item xs={12} key="content">
            <CardContent className="Content" style={{backgroundColor:'#bf7d40'}}>
                <Typography className="Title">
                    {props.item.Name}
                </Typography>

                <Typography className="Caption">
                    {props.item.Caption}
                </Typography>

                <Button variant="outlined" className="ViewButton">
                    View Now
                </Button>
            </CardContent>
        </Grid>
    )


    for (let i = 0; i < mediaLength; i++) {
        const item = props.item.Items[i];

        const media = (
            <Grid item xs={12} key={item.Name}>
                <CardMedia
                    className="Media"
                    image={item.Image}
                    title={item.Name}
                >
                    <Typography className="MediaCaption">
                        {item.Name}
                    </Typography>
                </CardMedia>

            </Grid>
        )

        items.push(media);
    }

   /*  if (contentPosition === "left") {
        items.unshift(content);
    } else if (contentPosition === "right") {
        items.push(content);
    } else if (contentPosition === "middle") {
        items.splice(items.length / 2, 0, content);
    } */

    return (
        <Card raised className="Banner">
            <Grid container spacing={0} className="BannerGrid">
                {items}
            </Grid>
        </Card>
    )
}

const items = [
    {
        Name: "Welcome to Victoria Falls",
        Caption: "We are here to serve",
        contentPosition: "left",
        Items: [
            {
                Name: "Victoria Falls",
                Image: falls1
            },
           
        ]
    },
    {
        Name: "Welcome to Victoria Falls",
        Caption: "We are here to serve",
        contentPosition: "left",
        Items: [
            {
                Name: "Victoria Falls",
                Image: falls2
            },
           
        ]
    },
    {
        Name: "Wild Life",
        Caption: "Enjoy African Safari",
        contentPosition: "middle",
        Items: [
            {
                Name: "Elephants",
                Image: "https://www.victoriafalls-guide.net/image-files/ele4.jpg"
            },
            {
                Name: "Elephants",
                Image: "https://travel.safari.com/wp-content/uploads/2019/01/Modi-oa-Tunya-national-park.jpg"
            }
        ]
    },
    {
        Name: "Accomodation",
        Caption: "Best Places to Stay",
        contentPosition: "right",
        Items: [
            {
                Name: "Explore",
                Image: "https://static.wheretostay.co.za/resources/1/1/9/11905/210313_xl.jpg"
            },
            {
                Name: "Explore",
                Image: "https://www.jacadatravel.com/wp-content/uploads/2016/09/accommodation_photos__Hi_LW6061_28077030_Staneleys-High-Tead.jpg"
            }
        ]
    }
]

class BannerExample extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            autoPlay: true,
            timer: 500,
            animation: "fade",
            indicators: true,
            timeout: 500,
            navButtonsAlwaysVisible: false
        }

        autoBind(this);
    }

    toggleAutoPlay() {
        this.setState({
            autoPlay: !this.state.autoPlay
        })
    }

    toggleIndicators() {
        this.setState({
            indicators: !this.state.indicators
        })
    }

    toggleNavButtonsAlwaysVisible()
    {
        this.setState({
            navButtonsAlwaysVisible: !this.state.navButtonsAlwaysVisible
        })
    }

    changeAnimation(event) {
        this.setState({
            animation: event.target.value
        })
    }

    changeTimeout(event, value) {
        this.setState({
            timeout: value
        })
    }

    render() {
        return (
            <div style={{color: "#494949", zIndex:'-1'}}>
               
                <Carousel
                    className="Example"
                    autoPlay={this.state.autoPlay}
                    timer={this.state.timer}
                    animation={this.state.animation}
                    indicators={this.state.indicators}
                    timeout={this.state.timeout}
                    navButtonsAlwaysVisible={this.state.navButtonsAlwaysVisible}
                >
                    {
                        items.map((item, index) => {
                            return <Banner item={item} key={index} contentPosition={item.contentPosition}/>
                        })
                    }
                </Carousel>
                
{/* 
                <FormLabel component="legend">Options</FormLabel>
                <FormControlLabel
                    control={
                        <Checkbox onChange={this.toggleAutoPlay} checked={this.state.autoPlay} value="autoplay"
                                  color="primary"/>
                    }
                    label="Auto-play"
                />
                <FormControlLabel
                    control={
                        <Checkbox onChange={this.toggleIndicators} checked={this.state.indicators} value="indicators"
                                  color="primary"/>
                    }
                    label="Indicators"
                />
                <FormControlLabel
                    control={
                        <Checkbox onChange={this.toggleNavButtonsAlwaysVisible} checked={this.state.navButtonsAlwaysVisible} value="indicators" color="primary"/>
                    }
                    label="NavButtonsAlwaysVisible"
                />

                <FormControlLabel
                    control={
                        <RadioGroup name="animation" value={this.state.animation} onChange={this.changeAnimation} row
                                    style={{marginLeft: "10px"}}>
                            <FormControlLabel value="fade" control={<Radio color="primary"/>} label="Fade"/>
                            <FormControlLabel value="slide" control={<Radio color="primary"/>} label="Slide"/>
                        </RadioGroup>
                    }
                />

                <FormControlLabel
                    control={
                        <div style={{width: 300}}>
                            <Typography id="discrete-slider" gutterBottom>
                                Animation Duration (Timeout) in ms
                            </Typography>
                            <Slider
                                defaultValue={500}
                                getAriaValueText={() => `${this.state.timeout}ms`}
                                aria-labelledby="discrete-slider"
                                valueLabelDisplay="auto"
                                step={100}
                                marks
                                min={100}
                                max={2000}
                                onChange={this.changeTimeout}
                            />
                        </div>
                    }
                /> */}

            </div>

        )
    }
}

export default BannerExample;

import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
    DateTimePicker,
} from '@material-ui/pickers';
import { Button } from '@material-ui/core';

import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/brown'

const theme = createMuiTheme({
  palette: {
    primary:red,
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));



  

export default function TransferForm() {
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = React.useState(new Date());
    
  const handleDateChange = date => {
      setSelectedDate(date);
    };  

  const [state, setState] = React.useState({
    age: '',
    name: 'hai',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
    
  return (
    <MuiThemeProvider theme={theme}>
      <form className={classes.root} noValidate autoComplete="off">
         

       <TextField id="filled-basic" label="From"  />
       <TextField id="filled-basic"  label="To"  />
     
      <TextField id="outlined-basic" type="number" label="Number of People" inputProps={{ min: 0, max: 100 }}/>
          
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
             
            
             <KeyboardDatePicker
               margin="normal"
               id="date-picker-dialog"
               label="Date "
               format="MM/dd/yyyy"
               value={selectedDate}
               onChange={handleDateChange}
               KeyboardButtonProps={{
                 'aria-label': 'change date',
               }}
             />
             <KeyboardTimePicker
               margin="normal"
               id="time-picker"
               label="Time"
               value={selectedDate}
               onChange={handleDateChange}
               KeyboardButtonProps={{
                 'aria-label': 'change time',
               }}
             />
     
     
                       </MuiPickersUtilsProvider>
               
          <br></br>
          <Button
          
          variant="contained"
        
          style={{ width: "100px", textTransform:"none", color:"white", backgroundColor:"#ac7339" }}>
              Book
              </Button>
      </form>
      </MuiThemeProvider>
  );
}
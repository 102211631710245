import { FETCH_ACTIVITY_TYPES, START_FETCH_ACTIVITY_TYPES, FETCH_ACTIVITY_TYPE, NEW_ACTIVITY_TYPE,UPDATE_ACTIVITY_TYPE, DELETE_ACTIVITY_TYPE } from '../actions/types'

const initialState = {
    items: [],
    item: {},
    loading:true
}


export default function (state=initialState, action) {

    switch (action.type) {
        case START_FETCH_ACTIVITY_TYPES:
            if (state.loading)
                return {
                    ...state,
                    loading: false
                }
            else {
                return {
                    ...state,
                    loading: true
                }
            }
     
        case FETCH_ACTIVITY_TYPES:
            return {
                ...state,
                items: action.payload,
                loading: false
            };
            case FETCH_ACTIVITY_TYPE:
                return {
                    ...state, 
                    item: action.payload
            } ;
        case NEW_ACTIVITY_TYPE:
            return {
                ...state,
                item: action.payload,
            }
            case DELETE_ACTIVITY_TYPE:
                return {
                    ...state,
                    item:action.payload
            }
            case UPDATE_ACTIVITY_TYPE:
                return {
                    ...state,
                    item:action.payload
            }
        default:
            return state;
    }
}
import React, {useState, useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles,createMuiTheme,MuiThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { connect, useSelector, useDispatch } from 'react-redux';
import { register } from '../actions/userActions'
import { createBrowserHistory } from 'history';
import { withRouter } from 'react-router';
import red from '@material-ui/core/colors/brown'

const theme = createMuiTheme({
  palette: {
    primary:red,
  },
});

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" to="/">
        Kalundu Sands
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function RegisterForm(props) {
  const classes = useStyles();

    const registered = useSelector(state => state.user.registered);
    const errors=useSelector(state =>state.error.errors)  
  useEffect(() => {
    
    if(registered)
    props.history.push('/')

  },[registered])

  const dispatch = useDispatch();

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
    const [conpassword, setConPassword] = useState('')
  const [samepassword, setSamepassword] = useState(true)
  const [required, setRequired] = useState(false)

//handle events
function handleOnChangeName(event) {
  setName(event.target.value)
  }

  function handleOnChangeEmail(event) {
    setEmail(event.target.value)
  }

  function handleOnChangePass(event) {
    setPassword(event.target.value)
  }

  function handleOnChangeConPass(event) {
      setConPassword(event.target.value)
      if (event.target.value !== password)
          setSamepassword(false)
      else
        setSamepassword(true)
  }
  

  function handleSubmit() {

    if (name && email && password && conpassword) {
      const userDetails = {
        name: name,
        email: email,
        password: password,
        password_confirmation: conpassword
      }

      if (samepassword)
        dispatch(register(userDetails))
    }

    else {
      setRequired(true)
    }

}


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
     {/*    <Avatar className={classes.avatar} style={{color:"brown"}}>
          <LockOutlinedIcon />
        </Avatar> */}
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        {required && <Typography style={{color:'red'}}>All fields are required!</Typography>}
        {errors && <Typography style={{color:'red'}}>An error occured retry!</Typography>}
        
        <form className={classes.form} noValidate>
        <MuiThemeProvider theme={theme}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="Full Name"
                autoFocus
                onChange={handleOnChangeName}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={handleOnChangeEmail}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleOnChangePass}
              />
            </Grid>

                        <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Confirm Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleOnChangeConPass}
                          />
                          {!samepassword && <Typography>Passwords are not the same</Typography>}
            </Grid>          

            <Grid item xs={12}>
              
            </Grid>
            </Grid>
            </MuiThemeProvider>
          <Button

            fullWidth
            variant="contained"
            
            className={classes.submit}
            onClick={handleSubmit}
            style={{backgroundColor:'#c68c53', color:"white"}}
          >
              Sign Up
          </Button>
         
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/login" variant="body2"    style={{color:"black"}}>
                Already have an account? Sign in
              </Link>
                      </Grid>
                      
                      <Grid item style={{paddingLeft:"10px"}}>
              <Link href='/' variant="body2"  style={{color:"black"}}>
                Return to home page
              </Link>
            </Grid>
          </Grid>
          </form>
         
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default withRouter(RegisterForm)
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Search from "@material-ui/icons/Search";
import Email from "@material-ui/icons/Email";
import Face from "@material-ui/icons/Face";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Explore from "@material-ui/icons/Explore";
// core components
import GridContainer from "./components/Grid/GridContainer.js";
import GridItem from "./components/Grid/GridItem.js";
import Header from "./components/Header/Header.js";
import CustomInput from "./components/CustomInput/CustomInput.js";
import CustomDropdown from "./components/CustomDropdown/CustomDropdown.js";
//import Button from "./components/CustomButtons/Button.js";
import Button from "@material-ui/core/Button";
import image from "../assets/img/bg.jpg";
import profileImage from "../assets/img/myprofile.png";
import logo from "../assets/img/logo1.png";

import styles from "../assets/jss/material-kit-react/views/componentsSections/navbarsStyle.js";
import { Toolbar, Typography } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import { BrowserRouter as Router, Route, NavLink, Link } from 'react-router-dom'
import SettingsIcon from '@material-ui/icons/Settings';
import { connect, useSelector, useDispatch } from 'react-redux';
import { logout } from '../actions/userActions'
import { withRouter } from 'react-router';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const useStyles = makeStyles(styles);

function NavBar(props) {


  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const loggedin = useSelector(state => state.user.loggedin)
  const user = useSelector(state => state.user.user)
  const cart = useSelector(state => state.cart.items)

//drop down menu

const handleMenu = event => {
  setAnchorEl(event.currentTarget);
};

  
const handleClose = () => {
  setAnchorEl(null);
  };

  const handleLogout = () => {
     dispatch(logout(() => {
      props.history.push('/')
  })); 
    setAnchorEl(null);
  };
  const classes = useStyles();
  return (
    <React.Fragment>
      <Header
            brand="Kalundu Sands"
            color="dark"
            rightLinks={
              <List className={classes.list}>

<Router/>
<ListItem className={classes.listItem}>
                
            
                <Button
                    className={classes.myLinks}
                  >
                    Activities
                  </Button>
                </ListItem>

                <ListItem className={classes.listItem}>
                  <Button
                  className={classes.myLinks}
                  >
                    Tour
                  </Button>
                </ListItem>

                <ListItem className={classes.listItem}>
                  <Button
                  className={classes.myLinks}  
                  >
                    Accomodation
                  </Button>
                </ListItem>

                <ListItem className={classes.listItem}>
                  <Button 
                  className={classes.myLinks}  
                  >
                    Specials
                  </Button>
                </ListItem>

                <ListItem className={classes.listItem}>
                  <Button
                    className={classes.myLinks}
                  >
                   
                    <NavLink
                      className={classes.myLinks}
                      to="/aboutus" color="inherit">
                      About Us
                      </NavLink>
                  </Button>
                </ListItem>

                <ListItem className={classes.listItem}>
                  <Button
                    className={classes.myLinks}
                  >
                   
                    <NavLink
                      className={classes.myLinks}
                      to="/book" color="inherit">
                      <ShoppingCartIcon /><span style={{ color: 'red' }}>{ cart.length>0?cart.length:''}</span>
                      </NavLink>
                  </Button>
                </ListItem>
                
                <ListItem style={{ paddingLeft: '50px' }} className={classes.listItem}>
                  
                <Button
                    className={classes.myLinks}
                  >
                   
                    {loggedin ? <CustomDropdown
                    left
                    caret={false}
                    hoverColor="black"
                    dropdownHeader={user.user.name}
                    buttonText={
                      <img
                        src={profileImage}
                        className={classes.img}
                        alt="profile"
                      />
                    }
                    buttonProps={{
                      className:
                        classes.navLink + " " + classes.imageDropdownButton,
                      color: "transparent"
                    }}
                    dropdownList={user.user.role==1?[
                      <NavLink
                      className={classes.myLinks}
                        to=""
                        color="inherit"
                        style={{color:'black', textDecoration:'none',textTransform:'none'}}>
                     Profile
                      </NavLink>,
                      <NavLink
                      className={classes.myLinks}
                        to="" color="inherit"
                        style={{color:'black', textDecoration:'none',textTransform:'none'}}>
                     Help
                      </NavLink>,
                      
                        <NavLink
                        className={classes.myLinks}
                          to="/admin"
                          color="inherit"
                          style={{color:'black', textDecoration:'none',textTransform:'none'}}>
                        Admin
                      </NavLink> ,
                      <Link onClick={handleLogout} style={{color:'black', textDecoration:'none',textTransform:'none'}} >Log out</Link>
                    ] :
                  
                    [
                      <NavLink
                      className={classes.myLinks}
                        to=""
                        color="inherit"
                        style={{color:'black', textDecoration:'none',textTransform:'none'}}>
                     Profile
                      </NavLink>,,
                      <NavLink
                      className={classes.myLinks}
                        to=""
                        color="inherit"
                        style={{color:'black', textDecoration:'none',textTransform:'none'}}>
                     Help
                      </NavLink>,
              
                <Link onClick={handleLogout} style={{color:'black', textDecoration:'none', textTransform:'none'}} >Log out</Link>
                    ] 
                  
                  }
                    style={{zIndex: "10000"}}
                  />  : <NavLink
                      className={classes.myLinks}
                      style={{
                        fontSize: "12px"
                      }}
                        to="/login"
                        color="inherit">
                      Login/Register
                      </NavLink>}
                  </Button>
                  
                </ListItem>
              </List>
            }
        
        mobileLinks={
          <List className={classes.list}>

<ListItem className={classes.listItem}  style={{ paddingLeft: '30px' }}>
                  <Button
                    className={classes.myLinks}
                  >
                   
                    <NavLink
                      className={classes.myLinks}
                      to="/book" color="inherit">
                      <ShoppingCartIcon /><span style={{ color: 'red' }}>{ cart.length>0?cart.length:''}</span>
                      </NavLink>
                  </Button>
</ListItem>
          
            
<ListItem className={classes.listItem}>
                  
                  <Button
                      className={classes.myLinks}
                    >
                     
                      {loggedin ? <CustomDropdown
                      left
                      caret={false}
                      hoverColor="black"
                      dropdownHeader={user.user.name}
                      buttonText={
                        <img
                          src={profileImage}
                          className={classes.img}
                          alt="profile"
                        />
                      }
                      buttonProps={{
                        className:
                          classes.navLink + " " + classes.imageDropdownButton,
                        color: "transparent"
                      }}
                      dropdownList={user.user.role==1?[
                        <NavLink
                        className={classes.myLinks}
                          to=""
                          color="inherit"
                          style={{color:'black', textDecoration:'none',textTransform:'none'}}>
                       Profile
                        </NavLink>,
                        <NavLink
                        className={classes.myLinks}
                          to="" color="inherit"
                          style={{color:'black', textDecoration:'none',textTransform:'none'}}>
                       Help
                        </NavLink>,
                        
                          <NavLink
                          className={classes.myLinks}
                            to="/admin"
                            color="inherit"
                            style={{color:'black', textDecoration:'none',textTransform:'none'}}>
                          Admin
                        </NavLink> ,
                        <Link onClick={handleLogout} style={{color:'black', textDecoration:'none',textTransform:'none'}} >Log out</Link>
                      ] :
                    
                      [
                        <NavLink
                        className={classes.myLinks}
                          to=""
                          color="inherit"
                          style={{color:'black', textDecoration:'none',textTransform:'none'}}>
                       Profile
                        </NavLink>,,
                        <NavLink
                        className={classes.myLinks}
                          to=""
                          color="inherit"
                          style={{color:'black', textDecoration:'none',textTransform:'none'}}>
                       Help
                        </NavLink>,
                
                  <Link onClick={handleLogout} style={{color:'black', textDecoration:'none', textTransform:'none'}} >Log out</Link>
                      ] 
                    
                    }
                      style={{zIndex: "10000"}}
                    />  : <NavLink
                        className={classes.myLinks}
                        style={{
                          fontSize: "12px"
                        }}
                          to="/login"
                          color="inherit">
                        Login
                        </NavLink>}
                    </Button>
                    
                  </ListItem>
            </List>

        }
      />

      
      </React.Fragment>

      
      
  );
}
export default withRouter(NavBar)
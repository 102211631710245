import React,{useState,useEffect} from "react";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { fetchActivity, newActivity,updateActivity } from "../actions/activityActions";
import { connect, useSelector, useDispatch } from 'react-redux';
import Alert from '@material-ui/lab/Alert'
import { clearErrors } from '../actions/errorActions'
import Paper from '@material-ui/core/Paper';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { fetchActivityTypes } from "../actions/activityTypeActions"
import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/brown'

const theme = createMuiTheme({
  palette: {
    primary:red,
  },
});

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: 'inline',
      textTransform: 'uppercase'
    },
    inlinePadding: {
      display: 'inline',
      marginLeft: '3ch'
    },
    margin: {
      margin: theme.spacing(1),
    },
    form: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        },
        width: '100%'
    },
    paper: {
      margin: theme.spacing(1),
      padding: theme.spacing(1, 3, 1, 1)
    }
  }));


export default function EditActivityForm(props) {

  console.debug(props.activity)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchActivityTypes())
  }, [])
    
  const categories = useSelector(state => state.activitytype.items)
  //const activity= useSelector(state => state.activity.item)
    const classes = useStyles();
    const [name, setName] = useState(props.activity.name)
  const [description, setDescription] = useState(props.activity.details)
  const [price, setPrice] = useState(props.activity.price)
 // const [description, setDescription] = useState('')
  
  const[category, setCategory]=useState(props.activity.type.id)
    

  const handleChangeC =(e)=> {
      setCategory(e.target.value)
    };

  const [myerror, setErrors]=useState()
  
    const handleChange =(e)=> {
     
     if(e.target.name=='name')
        setName(e.target.value)
      
     else if(e.target.name=='description')
        setDescription(e.target.value)
      
     else if(e.target.name=='price')
      setPrice(e.target.value)
    };

    const handleChange2 =(e)=> {
        setDescription(e.target.value)
      };

    const [openedForm, setOpenedForm] = React.useState(false);
    useEffect(() => {
        
      const timer = setTimeout(() => {
        dispatch(clearErrors())
        setErrors('')
        
      }, 5000);
      return () => clearTimeout(timer);

        }
        , [openedForm])
    
        const handleOpen = () => {
  
            if (openedForm)
              
            setOpenedForm(true)
              else
            setOpenedForm(true)
          }
          
          const handleSubmit = e => {
            e.preventDefault();
           
            if (name!='') {
              const activity = {
               type:category,
                  name: name,
                description: description,
                  price:price
              }
              dispatch(updateActivity(activity,props.activity.id))
              props.update();
              props.onClose();
              
            }
            else
            setErrors("Name is required ")
    
    }
    
          const errors = useSelector(state => state.error.errors)
          const success = useSelector(state => state.error.success)
    return (
        <div>
            <br/>
           
            {errors && <Alert severity="error">{errors.message}</Alert>} 
        {success && <Alert severity="success">Successfully  added</Alert>}
       
           
          
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start">
          <Grid
                    item
                md={10}>
            <Paper elevation={3}
            
            >
                <MuiThemeProvider theme={theme}>
              <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
              {myerror && <Alert severity="error">{myerror}</Alert>} 
                <Typography style={{ textAlign: 'center' }} variant="overline" display="block" gutterBottom>
                  Update Activity Form
    </Typography>
          <div>
         
        {/*   <TextField
             label="Current Category"
                    value={props.activity.type.name}
                    name='names'
               fullWidth
                      disabled
                      
                  /> */}
          <InputLabel htmlFor="category">Category</InputLabel>
                <Select
                                    native
                                    value={category}
                      onChange={handleChangeC}
                      defaultValue={1}
          inputProps={{
            name: 'category',
            id: 'category',
          }}
                                >
                                     <option aria-label="None" value="" />
          {categories.map(category =>
            <option value={category.id}>{category.name}</option>
                                        )}
                        </Select>
                            </div>
                            <div>
         
         <TextField
             label="Name"
                    value={name}
                    name='name'
             onChange={handleChange}
             fullWidth
 
                  />
                  <TextField
                    label="Description"
                    name='description'
             value={description}
             onChange={handleChange}
             fullWidth
 
                  />
                  <TextField
                    label="Price"
                    name='price'
             value={price}
             onChange={handleChange}
             fullWidth
 
         />
     </div>
           
                <Button type="submit" size="small" variant="contained" color="primary" className={classes.margin}>Submit</Button>
                <Button  size="small" variant="contained" color="secondary"  className={classes.margin} onClick={props.onClose}>Cancel</Button>
                </form>
                </MuiThemeProvider>
          </Paper>
          </Grid>
          </Grid>
            
            </div>
    )
}
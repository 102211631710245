import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import HomeForms from "./HomeForms"
import { Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import Example1 from "./components/Example1.js";
import ActivitiesHome from "./components/ActivitiesHome.js";
import  ActivitiesHome1 from "./components/ActivitiesHome1.js";
import GridContainer from "./components/Grid/GridContainer.js";
import GridItem from "./components/Grid/GridItem.js";
import styles from "../assets/jss/material-kit-react/views/componentsSections/pillsStyle.js";
import { withRouter } from 'react-router';

const useStyles = makeStyles(styles);

 function Home() {
    
    const classes = useStyles();
    return (
    
        <div>
            <Grid
            container>
                <Grid
                    item
                    xs={12}
            /* style={{ paddingLeft: '100px' }} */
          >
                     <HomeForms/>
                </Grid>
            </Grid>
            
              <div className={classes.section}>
                    <div className={classes.container}>
                        
                    
              
              <hr></hr>
               
                 <GridContainer>
                  <GridItem
                  xs="12" md='6'>
                    <Typography>  <span style={{ fontStyle: "italic", color: "#ac7339" }}>Victoria Falls Kalundu Sands </span> <br>
                    </br> <br/>Your guide to an adventure experience that brings you to meaning, connection, and freedom. 
  
  </Typography>  </GridItem>
                  <GridItem
                   xs="12" md='6'>
                    <Example1></Example1>
                    </GridItem>
                </GridContainer>
                
  
        
            
                <GridItem>
                
                <hr></hr>
                  Adrenalin hunters
                   
                    <ActivitiesHome/>
                       
                      
                      
                  
    
                  
              </GridItem>
              <GridItem>
                
                <hr></hr>
                  Enjoy the stay
                   
                    <ActivitiesHome1/>
                       
                      
                      
                  
    
                  
                </GridItem>
                </div>
            </div>           
        </div>
)


}

export default withRouter(Home)
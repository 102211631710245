import React,{useEffect} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "./components/Grid/GridContainer";
import GridItem from "./components/Grid/GridItem";
import { Button, Typography } from "@material-ui/core";
import CardMedia from '@material-ui/core/CardMedia';
import { Link } from "react-router-dom";
import { fetchActivityTypes } from '../actions/activityTypeActions'
import { connect, useSelector, useDispatch } from 'react-redux';
import CategoryTable from './CategoryTable'
import NewCategoryForm from './NewCategoryForm'




export default function AdminTypes(){

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchActivityTypes())
    }, [])

    const activityTypes=useSelector(state=>state.activitytype.items)

    return (

        
        <React.Fragment>
       
            <GridContainer >
            
                <GridItem md={12}>
              
               <NewCategoryForm/>
                 
                    
                    <CategoryTable categories={activityTypes}/>

                </GridItem>
              


             </GridContainer>
           
        </React.Fragment>
    )
}
import { combineReducers } from 'redux';
import activityReducer from './activityReducer';
import activityTypeReducer from './activityTypeReducer';
import bookingReducer from './bookingReducer';
import errorReducer from './errorReducer';
import userReducer from './userReducer';
import cartReducer from './cartReducer'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'


const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user','cart']
}



const rootReducer= combineReducers({

    activitytype: activityTypeReducer,
    activity: activityReducer,
    error: errorReducer,
    booking: bookingReducer,
    user: userReducer,
    cart: cartReducer
    
    

});

export default persistReducer(persistConfig, rootReducer);
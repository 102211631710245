import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/brown'



import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
    DateTimePicker,
} from '@material-ui/pickers';
import { Button } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary:red,
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));



  

export default function AccomodationForm() {
  const classes = useStyles();

  const [checkInDate, setCheckInDate] = React.useState(new Date());
  const [checkOutDate, setCheckOutDate] = React.useState(new Date());
    
  const handleInDateChange = date => {
    setCheckInDate(date);
  };

  const handleOutDateChange = date => {
    setCheckOutDate(date);
  };

  const [state, setState] = React.useState({
    age: '',
    name: 'hai',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
    
  return (
    <MuiThemeProvider theme={theme}>
      <form className={classes.root} noValidate autoComplete="off">
          
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
             
            
             <KeyboardDatePicker
               margin="normal"
               id="date-picker-dialog"
               label="Check In "
               format="MM/dd/yyyy"
               value={checkInDate}
               onChange={handleInDateChange}
               KeyboardButtonProps={{
                 'aria-label': 'change date',
               }}
             />
            <KeyboardDatePicker
               margin="normal"
               id="date-picker-dialog"
               label="Check Out"
               format="MM/dd/yyyy"
               value={checkOutDate}
               onChange={handleOutDateChange}
               KeyboardButtonProps={{
                 'aria-label': 'change date',
               }}
             />
     
     
                       </MuiPickersUtilsProvider>
     
      <TextField id="filled-basic" inputProps={{ min: 0, max: 100 }} type="number" label="Adults"  />
        <TextField id="filled-basic" inputProps={{ min: 0, max: 100 }} type="number" label="Children" />
          <br></br>
          <Button
          
          variant="contained"
        
          style={{ width: "100px", textTransform:"none", color:"white", backgroundColor:"#ac7339" }}>
              Book
              </Button>
      </form>
      </MuiThemeProvider>
  );
}
import { ADD_CART, REMOVE_CART,CLEAR_CART } from './types';


export function addToCart(item) {

   // localStorage.removeItem('access_token');
    return function (dispatch) {
        dispatch({
            type: ADD_CART,
            payload:item
            //delete from local storage
        })
        };
    
    
}

export function removeFromCart(item) {

    // localStorage.removeItem('access_token');
     return function (dispatch) {
         dispatch({
             type: REMOVE_CART,
             payload:item
             //delete from local storage
         })
         };
     
     
}
 
export function clearCart(item) {

    // localStorage.removeItem('access_token');
     return function (dispatch) {
         dispatch({
             type: CLEAR_CART,
            // payload:item
             //delete from local storage
         })
         };
     
     
 }
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "./components/Grid/GridContainer";
import GridItem from "./components/Grid/GridItem";
import { Typography } from "@material-ui/core";
import CardMedia from '@material-ui/core/CardMedia';




export default function AboutUs(){



    return (

        <React.Fragment>
            <div style={{ minHeight: '80vh'}}>
          <Typography variant="h5" component='h2'> About Us</Typography>  
            <GridContainer >
            <GridItem md={2}></GridItem>
                <GridItem md={8}>
<br></br>
                    <Typography align='justify'>
                        
                        <b>Victoria Falls Kalundu Sands</b> - Your guide to an adventure experience that brings you to meaning, connection, and freedom.
                        Our story began in 2014, back then we were the leading taxi supplier in the resort town of Victoria Falls Zimbabwe, we have since expanded to minimize your adventure search for the perfect one. Our job is to eliminate the stress of arranging your next getaway. Our mission is to provides our client the best possible services in most competitive price without compromising on quality and safety. Victoria Falls Kalundu sands overarching mission is to serve as the hub and facilitator for all our adventure travel community, it’s a one stop shop. We boast of our ground handling partners with 4 decades of experience, over 2.5 million clients and dedicated staff at your disposal.

                    </Typography>
                    <br></br>
                    <Typography align='justify'>
                  <b> Values</b> 
-Our values represent what we stand for. When it comes to our service, we make sure that we keep in mind even a minute detail to serve our client and maintain a long-term business relationship with them. Our goal is to become one of the leading in adventure connection, by providing services that surpass client expectation whenever possible

                    </Typography>

                </GridItem>

             </GridContainer>
            <p>
            

                </p>
                </div>
        </React.Fragment>
    )
}
import React, { useState,useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "./components/Grid/GridContainer";
import GridItem from "./components/Grid/GridItem";
import { Button, Typography } from "@material-ui/core";
import CardMedia from '@material-ui/core/CardMedia';
import { Link } from "react-router-dom";
import BookingsTable from './BookingsTable'
import { fetchBookings } from '../actions/bookingActions'
import { connect, useSelector, useDispatch } from 'react-redux';





export default function AdminBookings() {
    
    const dispatch = useDispatch()
    const[page, setPage]=useState('view')

    useEffect(() => {
    
        dispatch(fetchBookings())
    }, [])

    const bookings=useSelector(state=>state.booking.items)



    const handlePages=(e) => {
        console.log(e.currentTarget.name)
            if (e.currentTarget.name == 'view')
               setPage('view')
            else if (e.currentTarget.name == 'add')
             setPage('add')
            else if (e.currentTarget.name = '')
            setPage('activities')
    }

    return (

        
        <React.Fragment>
       
            <GridContainer >
            
                <GridItem md={12}>
                
                    <Typography align='justify'>
                        
                       Bookings
                    </Typography>
                    
                    <BookingsTable
                    bookings={bookings}
                    />
                </GridItem>
                
                   

         


             </GridContainer>
           
        </React.Fragment>
    )
}
import React, {useState, useEffect} from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ViewCart from './ViewCart'
import Link from '@material-ui/core/Link';
import { NavLink } from 'react-router-dom';
import { newBooking } from '../actions/bookingActions'
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { Card, Grid } from '@material-ui/core';
import { clearCart } from '../actions/cartActions'
import Swal from 'sweetalert2';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Select Activities', 'Confirm Booking', 'Payment'];
}



export default function HorizontalLabelPositionBelowStepper() {

  const dispatch = useDispatch()
  
  const [opened, setOpened]=useState(false)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] =useState('')

const errors = useSelector(state => state.error.errors)
  const cart = useSelector(state => state.cart.items)
  const user = useSelector(state => state.user.loggedin)
  const myuser = useSelector(state => state.user.user)
  const classes = useStyles();
 const [activeStep, setActiveStep] = React.useState(1);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setOpened(false)
  };

  const handleReset = () => {
    setActiveStep(0);
    };
    const preventDefault = (event) => event.preventDefault();
    const handleSubmit = (e) => {
    
        const booking = {
            name: name,
            email: email,
            phone:phone,
            total: cart.reduce((a, b) => a + (b['total_cost'] || 0), 0),
            activities:cart,
            
        }
        console.log(booking)
        dispatch(newBooking(booking))
        dispatch(clearCart())
    
    }
    
    const handleChange=(e) =>{
        if (e.target.name == 'name')
            setName(e.target.value)
            else if (e.target.name == 'email')
            setEmail(e.target.value)
            else if (e.target.name == 'phone')
            setPhone(e.target.value)
    }

    const handleName = (e) => {
        setName(e.target.value)
  }
  const handleForm = (e) => {
        setOpened(true)
  }


  const handlePay = () => {
    Swal.fire({
      title: 'Complete payment?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c68c53',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Make Booking!'
    }).then((result) => {
     
      if (result.value) {
        handleSubmit()
      if (errors) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          footer: { errors }
        }
        ).then(
          //  
          console.log('helalsnn')
        )
      }
      else {
        Swal.fire(
          'Successful!',
          'Thank you for booking with us. Details have been emailed.',
          'success'
        )
      }
            
    }
        })
    }


    function getStepContent(stepIndex) {

    
        switch (stepIndex) {
          case 0:
            return 'Select campaign settings...';
          case 1:
            return (
               <ViewCart/> 
            );
          case 2:
          return (
            <React.Fragment>
              
         {(!user&&cart.length>0)&&<div><Link href='/login'>Login</Link> or <Button style={{textTransform:'none', color:'blue'}} onClick={handleForm}>Continue as Guest</Button></div>}
              {opened && <form>
                <Typography>Booking as a Guest</Typography>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid
                    item>
                    <FormControl >
                      <TextField id="filled-basic" name="name" value={name} onChange={handleChange} label="Name" variant="filled" />
                    </FormControl>
                  </Grid>
                  <Grid
                    item>
                    <FormControl >
                      <TextField id="filled-basic" name="email" value={email} onChange={handleChange} label="Email" variant="filled" />
                    </FormControl>
                  </Grid>
                  <Grid
                    item>
                    <FormControl >
                      <TextField id="filled-basic" name="phone" value={phone} onChange={handleChange} label="Phone" variant="filled" />
                    </FormControl>
                  </Grid>
                      
                </Grid>
             
                      
                      
              </form>} 
              {user && <div>
                <Card style={{ marginLeft:'auto',marginRight:'auto',  width:'300px'}}>
                <p>{myuser.user.name}</p>
                <p>{myuser.user.email}</p>
                  <p>{myuser.user.phone}</p>
                </Card>
              </div>}
          </React.Fragment>      
            );
          default:
            return 'Unknown stepIndex';
        }
      }
      
    
    

  return (
     <div className={classes.root}  style={{ minHeight: '60vh'}}>
      {/* <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper> */}
      {cart.length > 0 ? <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>All steps completed</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
            <div>
              <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
              <div>
                {activeStep == 2 &&
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    Back
              </Button>
                }

                           
                {activeStep == 1 &&
                  <Button variant="contained" style={{backgroundColor:'#c68c53'}} onClick={handleNext}>
                    Complete Booking
                              </Button>}
                {activeStep == 1 &&
                                
                  <NavLink
                              
                    to="/"
                    color="inherit"
                    style={{ marginLeft: '10px', color: 'black', textDecoration: 'none', textTransform: 'none' }}>
                    Add more
                                </NavLink>
                                
                }
                {activeStep == 2 &&
                  ((opened || user) && <Button variant="contained" style={{backgroundColor:'#c68c53'}} onClick={handlePay}>
                    Pay
                              </Button>)}
              </div>
            </div>
          )}
          
      </div> :
        <div style={{ position: 'relative', paddingTop:'100px', fontSize: "18" }}>You have no Items in the Cart   <NavLink
                              
        to="/"
        color="inherit"
        style={{ marginLeft: '10px', color: 'brown', textDecoration: 'none', textTransform: 'none' }}>
        Add Items
                    </NavLink></div>}
    </div>
  );
}
